import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const kaltsiumSulfaat = () => (
  <Layout>
    <SEO title="Kaltsiumsulfaat-paneelid" />

    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
            <h1>KALTSIUMSULFAAT-PANEELID</h1>
            <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
    </div>

    <div
      className="epoporandad"
      style={{
        maxWidth: 1300,
        margin: "auto",
      }}
    >
      <div className="wrapper-epo">
        <Link to="/tostetudporandad/alajaamade-susteem/">
          <div className="epo">
            <h2>Alajaamade süsteem</h2>
          </div>
        </Link>
        <Link
          to="/tostetudporandad/kaltsiumsulfaat-paneelid/"
          activeStyle={{ borderBottom: "3px solid #ffa609" }}
        >
          <div className="epo">
            <h2>Kaltsiumsulfaat-paneelid</h2>
          </div>
        </Link>
        <Link to="/tostetudporandad/puitlaastpaneelid/">
          <div className="epo">
            <h2>Puitlaastpaneelid</h2>
          </div>
        </Link>
      </div>

      <div className="kaltsiumsulfaat-wrapper">
        <div className="kaltsiumsulfaat-text">
          <h3>Kaltsiumsulfaat-paneelid</h3>
          <p>
            Kaltsiumsulfaat paneelid vastavad kõrgematele tuleohutusnõuetele F60
            Samuti on nende paneelide akustilised omadused suurepärased. Tänu
            materjali suurele tihedusele (1500 kg/m3) on nad ka väga suure
            koormustaluvusega. Saadaval on eri tüüpi paneele, vastavalt
            vajadusele. Paneeli pealminemine osa on kas haljas (katteta) või
            kaetud alumiinimfooliumi, terasplaadi, melamiini, PVC, linoleumi,
            kummi, vaiba, tugeva laminaadi või kiviplaadiga. Paneeli servad on
            lõigatud 5 kraadise nurga alla ning servatud PVC ribaga. Paneeli
            alumine osa on kas haljas või kaetud alumiiniumfooliumiga
            (niiskuskindluse tagamiseks) või siis kaetud galvaniseeritud
            terasplaadiga, mis on mõeldud kõrgete koormustega aladele Paneelide
            standardmõõdud on 600 x 600 mm, kuid soovi korral on võimalikud ka
            teised mõõdud. Paneelide paksus on 28 või 38 mm, ilma katteta. Soovi
            korral on võimalikud muud paneelipaksused. Kõik paneelitüübid on
            testitud.
          </p>
        </div>
        <div className="subpage-img kaltsiumsulfaat-img"></div>
      </div>
    </div>
  </Layout>
)

export default kaltsiumSulfaat
